import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject, throwError } from 'rxjs';
import { StorageService } from './storage.service';
import {
  AuthRedirectService,
  AuthStorageService,
  UserIdService,
  WindowRef,
} from '@spartacus/core';
import { AppConstants } from '../constants/app-constant';
import { environment } from '../../../environments/environment';
import { AuthResponse } from '../models/authResponse';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {

  cartGroupId: any;
  cartId: any;
  public addressSubject = new BehaviorSubject<any>(null);
  public deliveryModeSubject = new BehaviorSubject<any>(null);
  public addressChangeQuotationSubject = new BehaviorSubject<any>(null);
  public deliveryModeChangeQuotationSubject = new BehaviorSubject<any>(null);
  public originalCartIdSubject = new BehaviorSubject<any>(null);
  address$ = this.addressSubject.asObservable();
  deliveryMode$ = this.deliveryModeSubject.asObservable();
  addressChangeQuotation$ = this.addressChangeQuotationSubject.asObservable();
  deliveryModeChangeQuotation$ = this.deliveryModeChangeQuotationSubject.asObservable();
  originalCartId$ = this.originalCartIdSubject.asObservable();

  constructor(
    httpClient: HttpClient,
    router: Router,
    storageService: StorageService,
    userIdService: UserIdService,
    authStorageService: AuthStorageService,
    authRedirectService: AuthRedirectService,
    windowRef: WindowRef
  ) {
    const apiURL = environment.siteUrls.getBaseUrl;
    super(
      httpClient,
      apiURL,
      router,
      storageService,
      userIdService,
      authStorageService,
      authRedirectService,
      windowRef
    );
  }

  /*-------------------------------------------------- COMMON METHODS --------------------------------------------------*/
  getUrlDetails(prefixVal?: string, siteNameVal?: string, userVal?: string, rest?: string) {
    let str = '';
    if (prefixVal === 'prefix') {
      str += environment.siteUrls.getPrefix;
    }
    if (siteNameVal === 'site') {
      str += environment.siteUrls.getSiteName;
    }
    if (userVal === 'user') {
      str += environment.siteUrls.getUser;
      this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    }
    if (userVal === 'userId') {
      this.userIdService.getUserId().subscribe((data) => (str += data));
    }
    if (userVal === 'b2bCustomers') {
      str += AppConstants.apiUrls.b2bCustomers;
      this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    }
    if (userVal === 'updateEmail') {
      str += AppConstants.apiUrls.updateAlternativeEmail;
      this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    }

    return str;
  }

  /*-- CMS Components --*/
  getCmsComponents(fields: string, pageType: string, cmpntIds: string): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.Get(
      url + 'cms/components?fields=' + fields + '&pageType=' + pageType + '&componentIds=' + cmpntIds
    );
  }

  /*-- Login --*/
  fetchOAuthToken(urlVal: string, postObj: any): Observable<AuthResponse> {
    return this.PostToken(urlVal, postObj);
  }

  getLegalEntityList(page: number, fields: string, pageSize: number, userIdVal: string): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');

    return this.Get(
      url + 'legalentities?userID=' + userIdVal + '&currentPage=' + page + '&fields=' + fields + '&pageSize=' + pageSize
    );
  }
  updateAddress(address: any) {
    this.addressSubject.next(address);
  }
  updateDeliveryMode(deliveryMode: any) {
    this.deliveryModeSubject.next(deliveryMode);
  }
  updateAddressChangeQ(addressChangeQuotation: any) {
    this.addressChangeQuotationSubject.next(addressChangeQuotation);
  }
  updateDeliveryModeChange(deliveryModeChangeQuotation: any) {
    this.deliveryModeChangeQuotationSubject.next(deliveryModeChangeQuotation);
  }

  updateOriginalCartId(cartId: string) {
    this.originalCartIdSubject.next(cartId);
  }

  getDefaultLegalEntity(fields: string): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.Get(url + '?fields=' + fields);
  }

  /*-- PLP --*/
  getProductsList(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.getProductsList,
      params
    );
  }

  /*-- PDP --*/
  getProductReferences(productCode: string, params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    let referenceUrl = AppConstants.apiUrls.pdpReferenceDetails.replace('<productCode>', productCode);
    return this.GetParamsHeaders(
      url + '/' + referenceUrl,
      params
    );
  }

  /*-- Home Page --*/
  getTopFeaturedProductList(page: number, fields: string, pageSize: number): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.Get(
      url + AppConstants.apiUrls.getProductsList + '?currentPage=' + page + '&fields=' + fields + '&pageSize=' + pageSize + '&query=::allCategories:"TOP FEATURED PRODUCTS"'
    );
  }

  getHomePageCategories(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.homePageCategories,
      params
    );
  }

  /*-- Mini Cart --*/
  getCartsListByLegalEntity(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.getCartByLegalEntity,
      params
    );
  }

  getOrderHistoryList(params: any, headers: any = {}): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.getOrderHistory,
      params,
      headers
    );
  }
  createNewCart(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.createCart,
      params
    );
  }

  /*-- Common Carts Functionalities --*/
  setActiveCart(cartId: string, params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    return this.PostParamsHeaders(
      url + '/' + AppConstants.apiUrls.getCarts + cartId + AppConstants.apiUrls.activeCart,
      params
    );
  }

  addToCart(cartId: string, params: any, requestObject: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.addToCart + cartId + '/entries',
      params,
      {},
      requestObject
    );
  }

  /*-- Carts --*/
  getCartsList(cartId, params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    let legalEntity: string = (this.storageService.defaultLegalEntity) ? this.storageService.defaultLegalEntity.uid : null;
    params['legalEntityId'] = legalEntity
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.getCarts + cartId,
      params
    );
  }

  updateCart(cartId, entryNumber, requestObject): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PutParamsHeaders(
      url +
      AppConstants.apiUrls.getCarts +
      cartId +
      AppConstants.apiUrls.updateCart +
      entryNumber,
      requestObject
    );
  }

  /*-- Manage Carts --*/
  createNewSavedCart(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.createCart,
      params
    );
  }

  duplicateCart(cartId: any, params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    const headers = { legalEntity: params['legalEntityId'] };
    return this.PostParamsHeaders(
      url +
      AppConstants.apiUrls.getCarts +
      cartId +
      AppConstants.apiUrls.getDuplicateCarts,
      params,
      headers,
      {},
      false
    );
  }

  createCart(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.createCart,
      params
    );
  }

  deleteCart(cartId, entryNumber): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.DeleteParamsHeaders(
      url +
      AppConstants.apiUrls.getCarts +
      cartId +
      AppConstants.apiUrls.deleteCart +
      entryNumber
    );
  }
  removeCart(cartGroupId, cartId): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.cartGroup + cartGroupId + '/rejectCartInGroup' + '?cartId=' +
      cartId, {}
    );
  }
  rejectOrderRequest(cartGroupId: string, commentVal: string): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    const encodedComment = encodeURIComponent(commentVal || '');
    return this.PostParamsHeaders(
      `${url}${AppConstants.apiUrls.cartGroup}${cartGroupId}/reject?comment=${encodedComment}`,
      '',
      {}
    );
  }
  deleteSavedCart(cartId): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.DeleteParamsHeaders(
      url + AppConstants.apiUrls.getCarts + cartId
    );
  }

  getSavedCartsList(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.getSavedCarts,
      params
    );
  }

  getSaveCartFilterList(): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.GetParamsHeaders(url + AppConstants.apiUrls.getFilterSavedCarts, {})
  }

  getCarouselImagesHome(fields: string, currentPage: number, pageSize: number, cmpntIds: string): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.Get(
      url + 'cms/components?fields=' + fields + '&currentPage=' + currentPage + '&pageSize=' + pageSize + '&componentIds=' + cmpntIds
    );
  }

  userssgredetails(params: any, headers: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'b2bCustomers');
    params['fields'] = 'BASIC';
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.usersdetails,
      params,
      headers
    );
  }

  getlegalentities(params: any, headers: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.legalEntities,
      params,
      headers
    );
  }

  getUserProfileList(params: any, userId: any, headers: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    return this.GetParamsHeadersUserProfile(
      url + '/users/' + userId + '/' + AppConstants.apiUrls.legalEntities,
      params,
      headers,
      ''
    );
  }

  getProfileList(params: any, headers: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');

    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.legalEntities,
      params,
      headers
    );
  }

  getLegalEntity(params: any, headers: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    return this.GetParamsHeaders(
      url + '/' + AppConstants.apiUrls.legalEntities,
      params,
      headers
    );
  }

  updateEmail(requestObject: any, email): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'updateEmail');
    return this.PutParamsHeaders(url + email, requestObject);
  }

  updateAccessRequest(requestObject: any, requestComment: any): Observable<any> {
    let currentDate = new Date().toISOString();
    let userDataId = this.getUrlDetails('', '', 'userId');
    let url = this.getUrlDetails('prefix', 'site', 'b2bCustomers');
    let cstmRequestAccess = `?comment=${requestComment}&updateDate=${currentDate}&updatedBy=` + userDataId;
    return this.Post(url + cstmRequestAccess, requestObject);
  }

  getCompanyList(params, header): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    return this.GetParamsHeaders(url + '/companies', params, header);
  }

  storeLanguageDate(dataObject: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'b2bCustomers');
    let cstmRequestAccess = (dataObject.dateFormat) ? `?dateFormat=${dataObject.dateFormat}` : `?languageISOCode=${dataObject.languageISOCode}`
    return this.Post(url + AppConstants.apiUrls.setDateAndLanguage + cstmRequestAccess, dataObject);
  }

  postLegalEntityListInternal(requestBody: any[], params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    let str;
    str = environment.siteUrls.getUser;
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    let userId = str.split('/');
    return this.PostParamsHeaders(
      url + '/b2bcustomers/' + userId[2] + '/inviteInternalUser?' + params,
      '',
      {},
      requestBody
    );
  }

  postLegalEntityListExteral(requestBody: any[], params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    let str;
    str = environment.siteUrls.getUser;
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    let userId = str.split('/');
    return this.PostParamsHeaders(
      url + '/b2bcustomers/' + userId[2] + '/inviteExternalUser?' + params,
      '',
      {},
      requestBody
    );
  }
  updateCustomer(userId: any, sgreAdmin: boolean, isinternal: boolean, requestBody: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    if (sgreAdmin != null) {
      return this.PutParamsHeaders(
        url + '/b2bcustomers/' + userId + '/?isInternalUser=' + isinternal + '&sgreAdmin=' + sgreAdmin,
        requestBody
      );
    } else {
      return this.PutParamsHeaders(
        url + '/b2bcustomers/' + userId + '/?isInternalUser=' + isinternal,
        requestBody
      );
    }
  }

  getAccessRequestList(params): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    let str = environment.siteUrls.getUser;
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    let userId = str.split('/');
    return this.GetParamsHeaders(url + '/b2bcustomers/' + userId[2], params);
  }

  updateAccessRequests(requestObject: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    let str = environment.siteUrls.getUser;
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    let userId = str.split('/');
    return this.PutParamsHeaders(
      url + '/b2bcustomers/' + requestObject.customerId + '/rejectaccessrequest?date=' + requestObject.date + '&legalEntityId=' + requestObject.legalEntityId + '&updatedBy=' + userId[2]
    );
  }

  acceptAccessRequest(requestObject: any, internal: any, requestBody: any) {
    let url = this.getUrlDetails('prefix', 'site');
    let str = environment.siteUrls.getUser;
    let legal_entity = encodeURIComponent(requestObject.legalEntity)
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    let userId = str.split('/');
    if (internal) {
      return this.PutParamsHeadersUserProfile(
        url +
        '/b2bcustomers/' +
        requestObject.userid +
        '/approveAccessRequest?date=' +
        requestObject.date +
        '&legalEntityId=' +
        legal_entity +
        '&isInternalUser=' +
        internal +
        '&updatedBy=' +
        userId[2] +
        '&sgreAdmin=' +
        requestObject.sgreAdmin,
        requestBody,
        '',
        requestObject.legalEntity
      );
    } else {
      return this.PutParamsHeadersUserProfile(
        url +
        '/b2bcustomers/' +
        requestObject.userid +
        '/approveAccessRequest?date=' +
        requestObject.date +
        '&legalEntityId=' +
        legal_entity +
        '&isInternalUser=' +
        internal +
        '&updatedBy=' +
        userId[2],
        requestBody,
        '',
        requestObject.legalEntity
      );
    }
  }

  RejectAccessRequest(requestObject: any) {
    let url = this.getUrlDetails('prefix', 'site');
    let str = environment.siteUrls.getUser;
    let legal_entity = encodeURIComponent(requestObject.legalEntity)
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    let userId = str.split('/');
    return this.PutParamsHeadersUserProfile(
      url +
      '/b2bcustomers/' +
      requestObject.userid +
      '/rejectaccessrequest?date=' +
      requestObject.date +
      '&legalEntityId=' +
      legal_entity +
      '&updatedBy=' +
      userId[2],
      '',
      '',
      requestObject.legalEntity
    );
  }

  getPendingRequest(param: any, requestObject: any, legalEntity: any) {
    let url = this.getUrlDetails('prefix', 'site');
    let str = environment.siteUrls.getUser;
    let legal_entity = encodeURIComponent(requestObject.legalEntity)
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    return this.GetParamsHeadersUserProfile(
      url +
      '/b2bcustomers/' +
      requestObject.userId +
      '/getPendingRequest?date=' +
      requestObject.date +
      '&legalEntityId=' +
      legal_entity +
      '&customerId=' +
      requestObject.userId,
      param,
      {},
      legalEntity
    );
  }

  getOrderRequestList(params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.getCartgroup,
      params
    );
  }
  postDuplicateCart(cartId: string, legalEntityId: string, cartName: string): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    const queryParams = new URLSearchParams({
      name: cartName,
      cartName: cartName,
      fields: 'FULL'
    }).toString();
    const finalUrl = `${url + AppConstants.apiUrls.getCarts}${cartId}/clonesavedcart?legalEntityId=${encodeURIComponent(legalEntityId)}&${queryParams}`;
    // Pass the selected legal entity directly in the headers
    const headers = {
      'legalEntity': legalEntityId
    };
    return this.PostParamsHeaders(finalUrl, {}, headers, {}, false);  // Set 'allowLegalEntity' to false to avoid auto-adding default

  }

  postToCheckout(cartId: string, params: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.getCarts + cartId + '/split', params
    );
  }

  getCountries() {
    let url = this.getUrlDetails('prefix', 'site');
    return this.Get(url + AppConstants.apiUrls.getCountries);
  }
  getRegion(countyIsoCode: string) {
    let url = this.getUrlDetails('prefix', 'site');
    return this.Get(url + AppConstants.apiUrls.getCountries + '/' + countyIsoCode + '/regions');
  }

  submitRequestAccess(data: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    return this.Post(url + AppConstants.apiUrls.submitRequestAccess, data);
  }
  getOrderDetailList(params, id): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    let str;
    str = environment.siteUrls.getUser;
    return this.GetParamsHeaders(url + 'abstractorders/' + id, params);
  }
  deleteorderdetail(orderId, entryNumber, params, comment): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PutParamsHeaders(
      url + AppConstants.apiUrls.orders + orderId + '/' + AppConstants.apiUrls.cancel + '?comment=' + comment + '&entriesIds=' + entryNumber,
      {},
      params
    );
  }
  deleteWholeOrder(orderid, params, comment): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsOrderdetails(
      url + AppConstants.apiUrls.deletewholeorder + '?orderId=' + orderid + '&comment=' + comment,
      {},
      comment
    );
  }
  recreate(orderid, params): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsRecreates(
      url + AppConstants.apiUrls.cart + AppConstants.apiUrls.createFromOrder + '?orderId=' + orderid + '&fields=FULL' + '&cartName=Recreated from ' + orderid, { headers: HttpHeaders }
    );
  }

  getIncoterms() {
    let url = this.getUrlDetails('prefix', 'site');
    return this.Get(url + '/incoterms');
  }
  getDeliveryMode(cartId: string, params: any = {}): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    return this.GetParamsHeaders(url + AppConstants.apiUrls.getCarts + cartId, params);
  }
  getDeliveryMethod(cartId: string, params: any = {}): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    return this.GetParamsHeaders(
      url + AppConstants.apiUrls.getCarts + cartId + '/deliverymodes',
      params
    );
  }
  putDeliveryMethod(cartId: any, deliveryModeId: string, params: any = {}): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    return this.PutParamsHeaders(
      url +
      AppConstants.apiUrls.getCarts +
      cartId +
      '/deliverymode' +
      '?deliveryModeId=' +
      deliveryModeId
    );
  }
  putIncoterm(cartId: any, selectedIncoterm: any, legalEntityId: any, params: any = {}): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    url += AppConstants.apiUrls.getCarts + cartId + '/incoterm' +
      '?incoterm=' + encodeURIComponent(selectedIncoterm) +
      '&legalEntityId=' + encodeURIComponent(legalEntityId)
    return this.PutParamsHeaders(url);
  }
  getShippingAddress(cartId: string, params: any = {}): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    params['fields'] = 'FULL';
    return this.GetParamsHeaders(
      url + '/' + AppConstants.apiUrls.getCarts + cartId + '/addresses',
      params
    );
  }
  putShippingAddress(cartId: any, addressId: string, params: any = {}): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    params['fields'] = 'FULL';
    return this.PutParamsHeaders(
      url +
      AppConstants.apiUrls.getCarts +
      cartId +
      '/addresses' +
      '/delivery' +
      '?addressId=' +
      addressId
    );
  }
  placeOrder(purchaseOrderNo: string, cartGroupId): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.cartGroup + cartGroupId + '/sendForApproval' + '?purchaseOrderNo=' + purchaseOrderNo,
      {}
    );
  }
  otherAddress(cartId: any, addressData: any): Observable<any> {
    const urlPart = `${this.getUrlDetails('prefix', 'site', 'user')}${AppConstants.apiUrls.getCarts}${cartId}/addresses/delivery`;
    const params = { fields: 'FULL' };
    const headers = {};
    const requestObject = {
      companyName: addressData.companyName,
      line1: addressData.street,
      town: addressData.city,
      postalCode: addressData.postalCode,
      country: { isocode: addressData.country.isocode },
      region: { isocode: addressData.region.isocode },
      contactPerson: addressData.contactPerson,
      phoneNumber: addressData.phoneNumber,
      firstName: "test",
      lastName: "test"
    };
    return this.PostParamsHeaders(urlPart, params, headers, requestObject);
  }
  requestOrder(purchaseOrderNo: string, cartGroupId): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.cartGroup + cartGroupId + '/sendForApproval' + '?purchaseOrderNo=' + purchaseOrderNo,
      {}
    );
  }
  cartgroupOrder(cartGroupId: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.GetParamsHeaders(
      url +
      AppConstants.apiUrls.cartGroup +
      cartGroupId, {}
    );
  }
  approveOrder(purchaseOrderNo: string, cartGroupId): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.cartGroup + cartGroupId + '/approve' + '?purchaseOrderNo=' +
      purchaseOrderNo, {}
    );
  }
  approveOrderRequest(purchaseOrderNo: string, cartGroupId): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user');
    return this.PostParamsHeaders(
      url + AppConstants.apiUrls.cartGroup + cartGroupId + '/approve' + '?purchaseOrderNo=' +
      purchaseOrderNo, {}
    );
  }

  getPowerBi(params: any, headers: any): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site', 'user',);
    return this.Get(url + AppConstants.apiUrls.powerbicharts + '?' + params);
  }

  postBulkOrder(requestBody: any, cartName: string): Observable<any> {
    let url = this.getUrlDetails('prefix', 'site');
    let str;
    str = environment.siteUrls.getUser;
    this.userIdService.getUserId().subscribe((data) => (str += data + '/'));
    let userId = str.split('/');
    let params: any = [];
    params['fields'] = 'FULL'
    let legalEntity: string = (this.storageService.defaultLegalEntity) ? this.storageService.defaultLegalEntity.uid : null;
    params['legalEntityId'] = legalEntity
    return this.PostParamsHeaders(
      url + '/users/' + userId[2] + '/cart/bulkorder?cartName=' + cartName,
      params,
      {},
      requestBody
    );
  }
}
